/* eslint-disable no-param-reassign */
import produce from 'immer';

import {
	TOGGLE_OPEN_CONTACT_MODAL,
	TOGGLE_OPEN_MEMBER_MODAL,
	SET_MEMBER_MODAL,
	TOGGLE_OPEN_MEETING_MODAL,
} from './actions';

const initState = {
	isOpenContactModal: false,
	isOpenMemberModal: false,
	isOpenMeetingModal: false,
	memberModal: null,
};

export default (oldState = initState, action) =>
	produce(oldState, (state) => {
		switch (action.type) {
			case TOGGLE_OPEN_CONTACT_MODAL: {
				state.isOpenContactModal = !state.isOpenContactModal;
				break;
			}
			case TOGGLE_OPEN_MEMBER_MODAL: {
				state.isOpenMemberModal = !state.isOpenMemberModal;
				break;
			}
			case SET_MEMBER_MODAL: {
				state.memberModal = action.payload;
				break;
			}
			case TOGGLE_OPEN_MEETING_MODAL: {
				state.isOpenMeetingModal = !state.isOpenMeetingModal;
				break;
			}
			default:
				break;
		}
	});
