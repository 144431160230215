/* eslint-disable import/prefer-default-export */
/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

// import '@babel/polyfill';

import './src/styles/style.scss';

// require('es6-promise/auto');

const { version } = require('./package.json');

export const onClientEntry = () => {
	if (localStorage && localStorage.getItem('billshark') !== version) {
		localStorage.removeItem('redux');
		localStorage.setItem('billshark', version);
	}
};

export const onRouteUpdate = ({ location }) => {
	window.dataLayer.push({
		event: 'page_view',
		page_view_url: location.pathname,
	});
	window.locations = window.locations || [document.referrer];
	window.locations.push(window.location.href);
	window.previousPath = window.locations[window.locations.length - 2];
};
