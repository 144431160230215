/* eslint-disable no-param-reassign */
import produce from 'immer';

import { RECORD_FETCH, RESET_RECORD, RESET_FORM_EVENT } from './actions';
import { REQUEST, FAILURE, SUCCESS } from '../../util/actionType';

const initState = {
	loading: false,
	error: false,
	lastRecord: {},
	records: [],
	resetForm: false,
};

export default (oldState = initState, action) =>
	produce(oldState, (state) => {
		switch (action.type) {
			case REQUEST(RECORD_FETCH): {
				state.loading = true;
				break;
			}
			case FAILURE(RECORD_FETCH): {
				state.loading = false;
				state.error = action.payload;
				break;
			}
			case SUCCESS(RECORD_FETCH): {
				state.error = false;
				state.records = [...state.records, action.payload];
				state.lastRecord = {
					...state.lastRecord,
					[action.payload.apiId]: action.payload,
				};
				state.loading = false;
				break;
			}
			case RESET_RECORD: {
				state.loading = false;
				state.error = false;
				state.lastRecord = {};
				state.records = [];
				break;
			}
			case RESET_FORM_EVENT: {
				state.resetForm = action.payload;
				break;
			}
			default:
				break;
		}
	});
