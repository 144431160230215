module.exports = [{
      plugin: require('../../../../../../../../local-cache/custom/dba27c31aad935787bb275c3e5e4e957708f15386de599eff1db476022cd7e4c/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"ApexEdge","short_name":"ApexEdge","description":"Enterprise Bill, Subscription, and Data Protection Solutions | Apexedge","lang":"en","start_url":"/","background_color":"#20232a","theme_color":"#20232a","display":"minimal-ui","icon":"/codebuild/output/src765927897/src/git-codecommit.us-east-1.amazonaws.com/v1/repos/apexedge-b2b-portal/src/images/icons/favIcon_new.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"093a35c2fee67fb237b6d3774bfee406"},
    },{
      plugin: require('../../../../../../../../local-cache/custom/dba27c31aad935787bb275c3e5e4e957708f15386de599eff1db476022cd7e4c/node_modules/gatsby-plugin-react-redux/gatsby-browser.js'),
      options: {"plugins":[],"pathToCreateStoreModule":"./src/state/createStore","serialize":{"space":0,"isJSON":true,"unsafe":false},"cleanupOnClient":false,"windowKey":"__PRELOADED_STATE__"},
    },{
      plugin: require('../../../../../../../../local-cache/custom/dba27c31aad935787bb275c3e5e4e957708f15386de599eff1db476022cd7e4c/node_modules/gatsby-plugin-use-query-params/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../../../../../../local-cache/custom/dba27c31aad935787bb275c3e5e4e957708f15386de599eff1db476022cd7e4c/node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../../../../../../../../local-cache/custom/dba27c31aad935787bb275c3e5e4e957708f15386de599eff1db476022cd7e4c/node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-KX7KC8Z","includeInDevelopment":true,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"dataLayerName":"dataLayer","routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../../../../../../../../local-cache/custom/dba27c31aad935787bb275c3e5e4e957708f15386de599eff1db476022cd7e4c/node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../../../../../../local-cache/custom/dba27c31aad935787bb275c3e5e4e957708f15386de599eff1db476022cd7e4c/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
