/* eslint-disable no-param-reassign */
import produce from 'immer';

import {
	SET_CURRENT_API_ID,
	SET_RECORD_TO_LOAD,
	RESET_RECORD_TO_LOAD,
} from './actions';

const initState = {
	currentApiId: null,
	recordToLoad: null,
};

export default (oldState = initState, action) =>
	produce(oldState, (state) => {
		switch (action.type) {
			case SET_CURRENT_API_ID:
				state.currentApiId = action.payload;
				break;
			case SET_RECORD_TO_LOAD:
				state.recordToLoad = action.payload;
				break;
			case RESET_RECORD_TO_LOAD:
				state.recordToLoad = null;
				break;
			default:
				break;
		}
	});
