/* eslint-disable no-param-reassign */
import produce from 'immer';

import {
	AUTH_LOGIN,
	AUTH_LOGOUT,
	AUTH_LOADING,
	AUTH_ERROR,
	AUTH_UPDATE_USER,
	AUTH_CHECK_LAST_VISITED_PAGE,
} from './actions';

const initState = {
	loading: false,
	error: false,
	isLogged: false,
	user: -1,
	checkLastVisitedPage: true,
};

export default (oldState = initState, action) =>
	produce(oldState, (state) => {
		switch (action.type) {
			case AUTH_LOADING:
				state.loading = true;
				break;
			case AUTH_ERROR:
				state.loading = false;
				state.error = true;
				break;
			case AUTH_UPDATE_USER:
				state.loading = true;
				state.user = action.payload;
				break;
			case AUTH_LOGIN:
				state.loading = false;
				state.error = false;
				state.isLogged = true;
				state.user = action.payload;
				break;
			case AUTH_LOGOUT:
				// Object.assign(state, initState);
				state.loading = false;
				state.error = false;
				state.isLogged = false;
				state.user = -1;
				break;
			case AUTH_CHECK_LAST_VISITED_PAGE:
				state.checkLastVisitedPage = action.payload;
				break;
			default:
				break;
		}
	});
