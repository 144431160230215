/* eslint-disable no-param-reassign */
import produce from 'immer';

import {
	SET_ACTIVE_ITEM,
	SET_ACTIVE_ITEM_FROM_SCROLL,
	SET_LAST_ACTIVE_ITEM,
	SET_ITEMS_TO_SPY,
	SET_ITEM_TO_SPY,
} from './actions';

const initState = {
	activeItem: null,
	lastActiveItem: null,
	itemsToSpy: [],
};

export default (oldState = initState, action) =>
	produce(oldState, (state) => {
		switch (action.type) {
			case SET_ITEMS_TO_SPY: {
				state.itemsToSpy = action.payload;
				break;
			}
			case SET_ITEM_TO_SPY: {
				state.itemsToSpy = state.itemsToSpy.map((item) => {
					if (item.id === action.payload.id) {
						return { ...action.payload };
					}
					return item;
				});
				break;
			}
			case SET_ACTIVE_ITEM: {
				state.activeItem = action.payload;
				break;
			}
			case SET_ACTIVE_ITEM_FROM_SCROLL: {
				state.activeItem = action.payload;
				break;
			}
			case SET_LAST_ACTIVE_ITEM: {
				state.lastActiveItem = action.payload;
				break;
			}
			default:
				break;
		}
	});
