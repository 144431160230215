import { combineReducers } from 'redux';
import { loadingBarReducer as loadingBar } from 'react-redux-loading-bar';
import auth from './auth/reducer';
import record from './record/reducer';
import api from './api/reducer';
import modal from './modal/reducer';
import scroll from './scroll/reducer';

export default combineReducers({
	auth,
	record,
	api,
	modal,
	scroll,
	loadingBar,
});
