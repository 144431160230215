import thunkMiddleware from 'redux-thunk';
import {
	createStore as reduxCreateStore,
	compose,
	applyMiddleware,
} from 'redux';
import { loadingBarMiddleware } from 'react-redux-loading-bar';
import promiseMiddleware from 'redux-promise-middleware';
import rootReducer from './reducers';
import persistState from './middlewares/persistor/persistState';

// const composeEnhancers =
// (typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
// compose;

const enhancer = compose(
	applyMiddleware(thunkMiddleware, promiseMiddleware, loadingBarMiddleware()),
	persistState()
);
export const createStore = () => reduxCreateStore(rootReducer, enhancer);

export default (preloadedState) => {
	return createStore(rootReducer, preloadedState, enhancer);
};
